import { adjustPersonBioDisplay } from "./adjustPersonBioDisplay";
export function getArtistContent() {
  if (!$(".people_item_link").length) return;

  const modalHTML = `
    <div id="inc--persons-myModal" class="inc__persons--modal-overlay">
      <div class="inc__persons--modal-content">
        <div class="modal-body--wrapper">
        <span class="inc__persons--close-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-292.05 415.85 10.1 10.1">
  <path d="M-286.4 420.9l4.3-4.3c.2-.2.2-.4 0-.6-.2-.2-.4-.2-.6 0l-4.3 4.3-4.3-4.3c-.2-.2-.4-.2-.6 0s-.2.4 0 .6l4.3 4.3-4.3 4.3c-.2.2-.2.4 0 .6.2.2.4.2.6 0l4.3-4.3 4.3 4.3c.2.2.4.2.6 0 .2-.2.2-.4 0-.6l-4.3-4.3z" fill="#000000"/>
</svg>
        </span>

        <div id="modal-body"></div>
        </div>
      </div>
    </div>
  `;
  $("body").append(modalHTML);

  // Wrap name and instrument elements for each artist link
  $(".people_item_link").each(function () {
    const name = $(this).find(".people_item_name");
    const instrument = $(this).find(".people_item_instrument");
    name.add(instrument).wrapAll('<div class="inc__name-instr--wrap"></div>');
  });

  // Handle click event to load artist content
  $("body").on("click", ".people_item_link", function (e) {
    e.preventDefault();
    loadArtistContent($(this).attr("href"));
  });

  // Close modal when the close button is clicked
  $("body").on("click", ".inc__persons--close-button", closeModal);

  // Close modal when clicking outside of the content area
  $(window).on("click", function (event) {
    const modal = $("#inc--persons-myModal")[0];
    if (event.target === modal) {
      closeModal();
    }
  });

  // Function to load artist content via AJAX
  function loadArtistContent(url) {
    $("body").addClass("preserve-scrollbar-width");
    // Push a new state into the browser's history
    history.pushState({ modalOpen: true }, null, url);

    // Listen for the back button and close the modal if open
    window.onpopstate = function (event) {
      // if (event.state && event.state.modalOpen) {

      closeModal(); // Custom function to close the modal
      // history.back(); // Go back to the actual previous page
      // }
    };

    $.ajax({
      url: url,
      type: "GET",
      success: function (response) {
        const htmlContent = $("<div/>").html(response);
        const personInfoBlock = htmlContent.find(".person_info_block").html();
        const personImage = htmlContent.find(".person_image").html();
        const personName = htmlContent.find(".person_name").html();
        const personInstrument = htmlContent.find(".person_position").html();

        const imageHTML = $("<div/>").addClass("inc__persons-image").append(personImage);
        const textBlock = $("<div/>")
          .addClass("inc__persons-text")
          .append(
            `<div class="inc__person-name-position"><h4 class="inc__person-name">${personName}</h4><h4 class="inc__person-instrument">${personInstrument}</h4></div>`
          )
          .append(personInfoBlock);
        const contentToAdd = $("<div/>").addClass("inc__persons-ajax-container").append(imageHTML).append(textBlock);

        const $modalBody = $("#modal-body");
        observeModalContent($modalBody[0]);

        $modalBody.html(contentToAdd); // Add the content

        showModal();
      },
      complete: function () {
        setTimeout(function () {
          $("body").removeClass("preserve-scrollbar-width");
        }, 1000); // Delay of 1000ms (1 second)
      },
      error: function (xhr, status, error) {
        console.error("Error loading artist content:", error);
        $("body").removeClass("preserve-scrollbar-width");
      },
    });
  }

  function observeModalContent(modalBody) {
    const observer = new MutationObserver(() => {
      // adjustPersonBioDisplay();
      observer.disconnect(); // Stop observing after the first adjustment
    });

    observer.observe(modalBody, { childList: true, subtree: true });
  }

  // Function to show modal
  function showModal() {
    const modal = $("#inc--persons-myModal");
    modal.css("display", "block");
    document.body.classList.add("no-scroll");
    setTimeout(() => modal.addClass("active"), 200);
  }

  // Function to close modal
  function closeModal() {
    const modal = $("#inc--persons-myModal");
    modal.css("display", "none").removeClass("active");
    document.body.classList.remove("no-scroll");
    const $modalContent = $(".inc__persons--modal-content");
    $modalContent.css("align-items", "center");
    // $modalContent.css("justify-content", "center");
    if (history.state && history.state.modalOpen) {
      history.back(); // Go back to the previous state in history
    }
  }
}
