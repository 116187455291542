export function revealh1h2() {
  gsap.registerPlugin(SplitText);

  // Initialize SplitText
  var split2 = new SplitText(".inc__hero--quote", {
    type: "words, chars",
    wordsClass: "inc__quote-word",
    charsClass: "inc__quote-letter",
  });

  // Create a timeline
  const tl = gsap.timeline();
  tl.set(".inc__hero--quote", { opacity: 1 });

  // Animate each character
  tl.to(split2.chars, {
    y: "0", // Matches the translateY(100%) in CSS
    opacity: 1, // Start from opacity 0
    duration: 1, // Duration of the animation
    ease: "cubic-bezier(0.2, 0, 0.5, 1)", // Matches your cubic-bezier easing
    stagger: {
      each: 0.02, // Matches the delay calc(var(--i) * 0.02s)
      from: "start",
    },
  });

  // ========

  // Check if h2 or .inc__side-quote exists
  //   const firstH2 = document.querySelector("h2");
  const sideQuote = document.querySelector(".inc__side-quote");

  let firstH2;

  if (sideQuote) {
    // Get all h2 elements before .inc__side-quote
    const h2Elements = [...document.querySelectorAll("h2")].filter((h2) => h2.compareDocumentPosition(sideQuote) & Node.DOCUMENT_POSITION_FOLLOWING);
    firstH2 = h2Elements.length > 0 ? h2Elements[0] : null;
  } else {
    // If no side-quote, select the first h2 in the document
    firstH2 = document.querySelector("h2");
  }

  // Create GSAP timeline
  const tlReveal = gsap.timeline({
    defaults: {
      ease: "cubic-bezier(0.2, 0, 0.5, 1)",
      // duration: 1,
    },
  });

  const revealSideQuote = () => {
    if (sideQuote) {
      gsap.to(sideQuote, {
        opacity: 1,
        duration: 1,
        ease: "cubic-bezier(0.2, 0, 0.5, 1)",
      });
    }
  };
  // Animate SplitText when h2 comes into view
  if (firstH2) {
    // firstH2.classList.add("inc__first-h2"); // Optional: Add a class to the h2

    const split2 = new SplitText(firstH2, {
      type: "words, chars",
      wordsClass: "inc__quote-word",
      charsClass: "inc__quote-letter",
    });

    ScrollTrigger.create({
      trigger: firstH2,
      start: "top 80%", // Start when the top of h2 enters 80% of the viewport
      once: true, // Trigger animation only once
      onEnter: () => {
        firstH2.classList.remove("inc__first-h2");
        firstH2.classList.add("inc__first-h2--after-split");

        // Animate h2 characters
        tlReveal.to(split2.chars, {
          y: "0",
          opacity: 1,
          stagger: {
            each: 0.02, // Adjust the stagger for smoother animation
            from: "start",
          },
        });

        // Start revealing side-quote before h2 animation completes
        if (sideQuote) {
          tlReveal.to(
            sideQuote,
            {
              opacity: 1, // Fade in
            },
            "-=0.5" // Starts 0.5 seconds before the previous animation ends
          );
        }

        // Cleanup SplitText after animation
        // tlReveal.add(() => {
        //   split2.revert();
        // });
      },
    });
  } else {
    console.log("there is no h2");
    if ($("#main_slide_wrapper").length) {
      // If no h2 exists, reveal the side-quote at the same position where h2 would have been revealed
      ScrollTrigger.create({
        trigger: ".inc__hero--quote",
        start: "bottom 10%", // Mimic the h2 trigger position
        once: true, // Trigger only once
        // markers: { startColor: "green", endColor: "red", label: "Side-Quote Trigger" },
        onEnter: revealSideQuote,
      });
    } else {
      revealSideQuote();
    }
  }
}
