export function textOverlayResize() {
  if ($(".inc__text-overlay").length > 0) {
    function adjustImageHeight() {
      const items = document.querySelectorAll(".inc__text-overlay");
      items.forEach((item) => {
        const textContainer = item.querySelector(".article_block_text_inner");
        const imgContainer = item.querySelector(".article_block_item_img");
        const wrapper = item;

        // Reset height before recalculating to ensure it's accurate
        imgContainer.style.height = "auto";

        // Calculate text height and set image container height
        const textHeight = textContainer.getBoundingClientRect().height;
        imgContainer.style.height = `${textHeight + 60}px`;
        wrapper.style.height = `${textHeight + 60}px`;
      });
    }

    // Debounce function to limit execution
    function debounce(func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), delay);
      };
    }

    // Adjust height on page load
    setTimeout(() => {
      adjustImageHeight();
    }, 500);

    // Adjust height with debounce on window resize
    const debouncedAdjustImageHeight = debounce(adjustImageHeight, 200); // Adjust delay as needed
    window.addEventListener("resize", debouncedAdjustImageHeight);
  }
}
