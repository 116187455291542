import { generateEnso } from "./html-elements";
import { generateLogo } from "./html-elements";

export function logoEnsoAnimation() {
  if ($(".inc__omodernt-behind-the-name .article_block_item_img").length) {
    const articleBlock = document.querySelector(".inc__omodernt-behind-the-name .article_block_item_img");
    articleBlock.innerHTML = "";

    const enso = generateEnso();
    const logo = generateLogo();

    articleBlock.innerHTML = enso + logo;

    const selectedEnso = document.querySelector("#animatedEnso");
    const selectedLogoObject = document.querySelector("#logoAnimated");

    let propsLogo;

    // Function to set up the logo's initial state
    const setupLogoAnimation = (logoElement) => {
      const logo = logoElement;

      propsLogo = {
        logo,
        logoPath: logo.querySelectorAll("path, rect, polygon"),
        logoPathTop: logo.querySelectorAll('[data-group="top"]'),
        logoPathTopLeft: logo.querySelectorAll('[data-group="top-left"]'),
        logoPathTopRight: logo.querySelectorAll('[data-group="top-right"]'),
        logoPathBottom: logo.querySelectorAll('[data-group="bottom"]'),
        logoPathBottomLeft: logo.querySelectorAll('[data-group="bottom-left"]'),
        logoPathBottomRight: logo.querySelectorAll('[data-group="bottom-right"]'),
      };

      const setInitialValues = (element, x = 0, y = 0, rotation = 0) => {
        gsap.set(element, {
          x: `+=${x}`,
          y: `+=${y}`,
          rotation: `+=${rotation}`,
          scale: 0,
          opacity: 0,
        });
      };

      // Set initial positions for all logo parts
      propsLogo.logoPathTop.forEach((el) => setInitialValues(el, 0, gsap.utils.random(-50, -10), gsap.utils.random(720, -720)));
      propsLogo.logoPathTopLeft.forEach((el) => setInitialValues(el, gsap.utils.random(-50, -10), gsap.utils.random(-50, -10), gsap.utils.random(720, -720)));
      propsLogo.logoPathTopRight.forEach((el) => setInitialValues(el, gsap.utils.random(10, 50), gsap.utils.random(-50, -10), gsap.utils.random(-720, 720)));
      propsLogo.logoPathBottom.forEach((el) => setInitialValues(el, 0, gsap.utils.random(50, 10), gsap.utils.random(-720, 720)));
      propsLogo.logoPathBottomLeft.forEach((el) => setInitialValues(el, gsap.utils.random(-50, -10), gsap.utils.random(50, 10), gsap.utils.random(720, -720)));
      propsLogo.logoPathBottomRight.forEach((el) => setInitialValues(el, gsap.utils.random(50, 10), gsap.utils.random(50, 10), gsap.utils.random(720, -720)));
    };

    // Wait for the SVG content to load and set up the animation
    selectedLogoObject.addEventListener("load", () => {
      setupLogoAnimation(selectedLogoObject);
    });

    // Function to handle animations when the object is in view
    const triggerAnimation = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && propsLogo) {
          const svgGroupInEnso = selectedEnso.querySelectorAll("g");

          // Create a GSAP timeline
          const timeline = gsap.timeline();

          // Enso animation
          timeline.to(svgGroupInEnso, {
            visibility: "visible",
            stagger: 0.03,
            // duration: 1.5,
            ease: "none",
            onComplete: () => {
              gsap.set(svgGroupInEnso, { visibility: "visible" });
            },
          });

          // Logo animation
          timeline.to(
            propsLogo.logoPath,
            {
              x: 0,
              y: 0,
              opacity: 1,
              scale: 1,
              rotation: 0,
              stagger: 0.02,
              // duration: 1,
            },
            "-=1"
          ); // Start 0.6 seconds before Enso animation ends

          observer.unobserve(entry.target); // Stop observing after the animation
        }
      });
    };

    // Set up the IntersectionObserver
    const observer = new IntersectionObserver(triggerAnimation, {
      root: null,
      threshold: 0.1,
    });

    if (selectedEnso) {
      observer.observe(selectedEnso);
    }
  }
}
