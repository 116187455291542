export function animateDynamicAcordeon() {
  $(".wrapper_block").on("click", ".inc__accordion_item_title_js", function (e) {
    e.preventDefault();
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).closest(".accordion_item_js").find(".accordion_item_content_js").slideUp(150);
      $(this).closest(".accordion_item_js").removeClass("inc__content-on");
    } else {
      $(this).closest(".inc__accordion_item_title_js").addClass("active");
      $(this).closest(".accordion_item_js").find(".accordion_item_content_js").slideDown(150);
      $(this).closest(".accordion_item_js").addClass("inc__content-on");
    }
  });
}
