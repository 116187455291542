import { createProgressBar } from "./modules/progress-bar";
import { initializeIframes } from "./modules/iframe";
import { appendTabs } from "./modules/tabs";
import { addRippleEffect } from "./modules/hover-ripple";
import { changeEventImage, startEventObserver, getEventsInfo, getEventsOnHomepage } from "./modules/events";
import { loadPastFestSlider } from "./modules/past-festivals";
import { styleQuoteAuthor, createSidequoteBlock } from "./modules/quotes";
import { updateNewspage } from "./modules/newspage";
import { addHeroText, animateFirstVisibleContent } from "./modules/animateText";
import { styleTeamPage } from "./modules/team";
import { getArtistContent } from "./modules/artist-content";
import { appendFestivalDates } from "./modules/appendFestivalDates";
import { smallPageUpdates } from "./modules/small-page-updates";
import { animateGallery } from "./modules/animatedGallery";
import { addPopup } from "./modules/popup";
import { animateDynamicAcordeon } from "./modules/accordeon";
import { logoEnsoAnimation } from "./animations/logo-plus-enso";
import { narrowEnsoPlusLogo } from "./animations/narrowEnsoPlusLogo";
import { revealh1h2 } from "./animations/h1-h2-reveal";
import { addArtistDefaultImg } from "./modules/adjustPersonBioDisplay";
import { animateSlidesInGallery } from "./modules/animatedGallery";
import { textOverlayResize } from "./modules/textOverlayResize";
import { getEventsByLabel } from "./modules/getEventsByLabel";
import { printPrograms } from "./modules/printPrograms";
import { supportPaypalBtns } from "./modules/support-paypal-btns";
$(document).ready(function () {
  if ($("body[class^='simple_page']")[0] !== undefined || $(".index_1")[0] !== undefined) {
    if (!$("body").hasClass("simple_page_115") && !$("body").hasClass("simple_page_113")) {
      console.log("creating placeholder");
      createProgressBar();
    }
  }

  appendTabs();
  addRippleEffect();
  changeEventImage();
  loadPastFestSlider();
  styleQuoteAuthor();
  createSidequoteBlock();
  updateNewspage();
  if ($(".index_1")[0] !== undefined) {
    addHeroText($(".index_1 .main_slide_title_1"), $(".index_1 .main_slide_title_2"));
  }
  // animateFirstVisibleContent();
  styleTeamPage();
  getArtistContent();
  appendFestivalDates();
  smallPageUpdates();
  animateGallery();
  addPopup();
  animateDynamicAcordeon();
  getEventsInfo();
  // logoEnsoAnimation();
  narrowEnsoPlusLogo();
  revealh1h2();
  addArtistDefaultImg();
  animateSlidesInGallery();
  textOverlayResize();
  if ($(".simple_page_115 ").length || $(".simple_page_113").length || $(".simple_page_114").length) {
    getEventsByLabel();
  }
  printPrograms();
  supportPaypalBtns();
});

startEventObserver();
getEventsOnHomepage();
document.addEventListener("DOMContentLoaded", () => {
  initializeIframes(); // Initialize the iframe handling when the DOM is loaded
});
