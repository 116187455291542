export function styleQuoteAuthor() {
  $("blockquote").each(function () {
    const paragraphs = $(this).find("p");
    if (paragraphs.length > 1) {
      paragraphs.last().addClass("inc__quote-source");
    }
  });
}

export function createSidequoteBlock() {
  const sideQuotes = $(".inc__side-quote");
  if (!sideQuotes.length) return;

  sideQuotes.each(function () {
    const blockquotes = $(this).find("blockquote").remove();
    const blockquoteHTML = blockquotes
      .map(function () {
        return `<blockquote class="inc__side-quote-text">${$(this).html()}</blockquote>`;
      })
      .get()
      .join(" ");

    $(this).find(".article_content_text").append(`<div class="inc__side-quote-content">${blockquoteHTML}</div>`);
  });
}
