function splitTitleIntoSpans(text) {
  const textToSplit = typeof text === "string" ? text : $(text).text();
  let letterCount = 0;

  return textToSplit
    .split("<br>")
    .map((segment) => {
      letterCount = 0;

      return segment
        .replace(/&nbsp;/g, "~")
        .split(" ")
        .map(
          (word) =>
            `<span class='inc__quote-word'>${[...word]
              .map((char) => {
                letterCount++;
                return `<span style="--i: ${letterCount};">${char === " " ? "&nbsp;" : char}</span>`;
              })
              .join("")}</span>`
        )
        .join("<span class='inc__quote-word'>&nbsp;</span>");
    })
    .join("<br>");
}

// function to check if element is in viewport
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top - $(window).scrollTop();
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportHeight = $(window).height();

  var isTopInView = elementTop >= 0 && elementTop < viewportHeight;
  var isBottomInView = elementBottom > 0 && elementBottom <= viewportHeight;

  // True if either the top or bottom of the element is within the viewport
  return isTopInView || isBottomInView;
};

export function addHeroText(animatedQuoteWithSpans = false, firstTitle = false, bottomSubtitle = false, animatedQuoteEqualsTitle = false) {
  let spanWrappedQuote = animatedQuoteWithSpans ? splitTitleIntoSpans(animatedQuoteWithSpans) : "";
  let title = firstTitle ? (typeof firstTitle === "string" ? firstTitle : firstTitle.text()) : "";
  const subtitle = bottomSubtitle;

  if (firstTitle && firstTitle[0] && !animatedQuoteEqualsTitle) {
    firstTitle.remove();
  }

  const createHeroText = (spanWrappedQuote, title, subtitle) => `
    <div class="inc__hero-mask"></div>
    <div class="inc__hero-text-wrapper">
      ${spanWrappedQuote ? `<h1 class="inc__hero--quote">${spanWrappedQuote}</h1>` : ""}
      ${title ? `<p class="inc__hero--title-sm inc__fade-in">${title}</p>` : ""}
      ${subtitle ? `<p class="inc__hero--subtitle inc__fade-in">${subtitle}</p>` : ""}
    </div>
  `;

  // Check for the presence of the hero text container
  const appendToElement = (elementSelector) => {
    if ($(elementSelector)[0] !== undefined) {
      $(elementSelector).append(createHeroText(spanWrappedQuote, title, subtitle));
    }
  };

  // Determine where to append the content
  if ($(".inc__hero-text-wrapper")[0] !== undefined) {
    appendToElement(".inc__hero-text-wrapper");
  } else if (animatedQuoteWithSpans && !firstTitle && !bottomSubtitle) {
    appendToElement("#main_slide_wrapper");
  } else if (animatedQuoteWithSpans && firstTitle && !animatedQuoteEqualsTitle) {
    appendToElement("#main_slide_wrapper");
  } else if (animatedQuoteWithSpans && firstTitle && animatedQuoteEqualsTitle) {
    appendToElement("#main_slide_wrapper");
  } else {
    appendToElement("#main_slide_wrapper");
  }

  // Special case for ".index_1"
  if ($(".index_1")[0] !== undefined) {
    appendToElement(".main_slider_wrapper");
  }
}

function revealFirstContentText() {
  const firstH2Element = $("h2").first();
  const sideQuoteElement = $(".inc__side-quote");

  if (firstH2Element.length || sideQuoteElement.length) {
    // Wrap the first h2 element
    firstH2Element.wrap('<div class="inc__content--h2"></div>');

    const h2Letters = splitTitleIntoSpans(firstH2Element.text()) || "";
    $(".inc__content--h2").html(h2Letters);

    const contentH2 = $(".inc__content--h2");
    const progressCircle = $(".inc__progress-circle-container");

    // Helper function to handle adding classes
    function animateElements() {
      contentH2.addClass("inc__animate--on");
      sideQuoteElement.addClass("inc__fade-in");
      progressCircle.addClass("inc__fade-in");
      $(window).off("resize scroll", checkViewportAndAnimate);
    }

    // Helper function to check if elements are in the viewport
    function checkViewportAndAnimate() {
      if (contentH2.length && contentH2.isInViewport()) {
        animateElements();
      }
      if (sideQuoteElement.length && sideQuoteElement.isInViewport()) {
        animateElements();
      }
    }

    // Initial viewport check and event listener
    $(window).on("resize scroll", checkViewportAndAnimate);
    checkViewportAndAnimate();

    // Check if scrolled past element
    const targetElement = sideQuoteElement.length ? sideQuoteElement : contentH2;
    setTimeout(() => checkIfScrolledPastElement(targetElement), 500);

    function checkIfScrolledPastElement(el) {
      const rect = el[0].getBoundingClientRect();
      if (rect.bottom < 0) {
        animateElements();
      }
    }
  }
}

function animateAndPositionH1() {
  const heroTextWrapper = $(".inc__hero-text-wrapper");
  const personName = $(".person_name");

  if (!heroTextWrapper.length && personName.length === 0 && $("h1").length) {
    const originalTitleInfoParts = $("h1").html().split("|");
    const [bigTitle, subtitle, subtitle2] = originalTitleInfoParts;

    const spanWrappedQuote = bigTitle ? splitTitleIntoSpans(bigTitle) : "";

    const mainSlideWrapper = $("#main_slide_wrapper");

    if (mainSlideWrapper.length) {
      $("h1").remove();

      mainSlideWrapper.append(`
        <div class="inc__hero-mask"></div>
        <div class="inc__hero-text-wrapper">
          <h1 class="inc__hero--quote">${spanWrappedQuote}</h1>
          ${subtitle ? `<p class="inc__hero--title-sm inc__fade-in">${subtitle}</p>` : ""}
          ${subtitle2 ? `<p class="inc__hero--subtitle inc__fade-in">${subtitle2}</p>` : ""}
        </div>
      `);
    } else {
      const h1Element = $("h1").html(spanWrappedQuote).attr("class", "inc__hero--quote");
      h1Element.wrapAll('<div class="inc__hero-text-wrapper inc__h1-no-bg-container"></div>');

      if (subtitle || subtitle2) {
        h1Element.after(`
          ${subtitle ? `<p class="inc__hero--title-sm inc__fade-in">${subtitle}</p>` : ""}
          ${subtitle2 ? `<p class="inc__hero--subtitle inc__fade-in">${subtitle2}</p>` : ""}
        `);
      }
    }
  }
}

export function animateFirstVisibleContent() {
  // setTimeout(animateAndPositionH1, 300);
  // revealFirstContentText();
}
