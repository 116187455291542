function createAnimatedGallery(image) {
  let htmlBegin = `
        <div class="inc__autoplay-gallery">
          <div class="inc__autoplay-gallery--wrapper">`;

  let htmlEnd = `
          </div>
        </div>`;

  var imageUrls = [];

  if ($(".inc__animated-gallery")[0] !== undefined) {
    image.each(function () {
      var url = $(this).attr("src");
      imageUrls.push(url);
    });
  } else {
    image.forEach(function (url) {
      if (url) {
        imageUrls.push(url);
      }
    });
  }

  imageUrls.forEach(function (url, index) {
    htmlBegin += `
<img src="${url}" alt="slide" class="inc__slide-img">  
  `;
  });

  if ($(".inc__animated-gallery")[0] !== undefined) {
    $(".inc__animated-gallery").empty();

    if ($(".inc__animated-gallery").hasClass("inc__hero-gallery")) {
      if ($("#event_page_main_slide")[0] !== undefined) {
        $("#event_page_main_slide").after(htmlBegin + htmlEnd);
        $("#event_page_main_slide").remove();
      }
    } else {
      $(".inc__animated-gallery").after(htmlBegin + htmlEnd);
    }
  } else {
    $("main").prepend(`<div id="main_slide_wrapper" class="main_slide_wrapper">` + htmlBegin + htmlEnd + `</div>`);
  }

  // $(".inc__animated-gallery").remove();
  const slides = $(".inc__slide-img");

  let currentIndex = 0;
  let previousIndex = -1; // Initialize to -1 since there is no previous index at the start

  function processItem(current, previous) {
    current.classList.add("is-show");

    if (previous !== -1) {
      // Check if there is a previous item
      setTimeout(function () {
        previous.classList.remove("is-show");
      }, 2000);
    }
  }

  function updateIndexes() {
    previousIndex = currentIndex; // Update previous index
    currentIndex = (currentIndex + 1) % slides.length; // Update current index, loop back to 0 if at the end
  }

  // Run the function immediately for the first time
  setTimeout(function () {
    const currentItem = slides[currentIndex];
    const previousItem = previousIndex >= 0 ? slides[previousIndex] : null;
    processItem(currentItem, previousItem);
    updateIndexes();
  }, 2);

  const intervalId = setInterval(() => {
    const currentItem = slides[currentIndex];
    const previousItem = previousIndex >= 0 ? slides[previousIndex] : null;

    processItem(currentItem, previousItem);
    updateIndexes();
  }, 4000); // Change interval as needed
}

export function animateGallery() {
  if ($(".inc__animated-gallery")[0] !== undefined) {
    createAnimatedGallery($(".article_image_block_slider .swiper-slide img"));
  }
  // if ($(".events_46")[0] !== undefined) {
  //   if ($("[data-imglinks]")[0] !== undefined && $("[data-imglinks]").attr("data-imglinks")) {
  //     var imgLinksArray = $("[data-imglinks]").attr("data-imglinks").split(",");
  //     createAnimatedGallery(imgLinksArray);
  //   }
  // }
}

export function animateSlidesInGallery() {
  if ($(".inc__slide-img").length) {
    const slides = $(".inc__slide-img");

    let currentIndex = 0;
    let previousIndex = -1; // Initialize to -1 since there is no previous index at the start

    function processItem(current, previous) {
      current.classList.add("is-show");

      if (previous !== -1) {
        // Check if there is a previous item
        setTimeout(function () {
          previous.classList.remove("is-show");
        }, 2000);
      }
    }

    function updateIndexes() {
      previousIndex = currentIndex; // Update previous index
      currentIndex = (currentIndex + 1) % slides.length; // Update current index, loop back to 0 if at the end
    }

    // Run the function immediately for the first time
    setTimeout(function () {
      const currentItem = slides[currentIndex];
      const previousItem = previousIndex >= 0 ? slides[previousIndex] : null;
      processItem(currentItem, previousItem);
      updateIndexes();
    }, 2);

    const intervalId = setInterval(() => {
      const currentItem = slides[currentIndex];
      const previousItem = previousIndex >= 0 ? slides[previousIndex] : null;

      processItem(currentItem, previousItem);
      updateIndexes();
    }, 4000); // Change interval as needed
  }
}
