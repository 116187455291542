export function adjustPersonBioDisplay() {
  const $modalContent = $(".inc__persons--modal-content");
  const $textContent = $("#inc--persons-myModal");
  const contentHeight = $textContent.outerHeight();
  const viewportHeight = $(window).height();

  if (contentHeight > viewportHeight) {
    $modalContent.css("align-items", "flex-start");
    // $modalContent.css("justify-content", "flex-start");
  }
}

export function addArtistDefaultImg() {
  if ($(".people_item_image").length) {
    // Loop through all .people_item_image elements
    $(".people_item_image").each(function () {
      // Get the <img> inside the current .people_item_image
      const img = $(this).find("img");

      // Check if the <img> has an empty or missing src
      if (!img.attr("src") || img.attr("src").trim() === "") {
        // Set the default src
        img.attr("src", "/uploads/images/temp/Screenshot%202023-09-10%20at%2021_09_34.jpg");
      }
    });
  }
}
