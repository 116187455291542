import { animateLoader } from "../animations/loader";
export function supportPaypalBtns() {
  if ($(".simple_page_120").length) {
    // suppport page
    $(document).on("click", "a[data-donationcurrency]", function (e) {
      e.preventDefault(); // Prevent default link behavior if needed
      $("body").append('<div class="swinger"></div>');
      $("body").addClass("swinger--loading");
      animateLoader();

      let currency = $(this).data("donationcurrency");
      $(`#${currency} form`).submit();
    });
  }
}
