export function addRippleEffect() {
  if ($("a")[0] !== undefined) {
    $("a").each(function (i, e) {
      if (
        e.innerText.toLowerCase().trim() === "visit page" ||
        e.innerText.toLowerCase().trim() === "download flyer" ||
        e.innerText.toLowerCase().trim() === "buy now"
      ) {
        $(e).addClass("btn btn-simple btn-block btn-fit");
        // $(e).prepend("<span class='inc__ripple-hover'></span>");
      } else if ($(e).closest(".press_item")[0] !== undefined && $(e).closest(".inc__cards-no-img--row")[0] !== undefined) {
        $(e).addClass("btn");
        $(e).find(".press_item_image").remove();
      }
      if ($(e).hasClass("btn") || $(e).attr("data-tab")) {
        $(e).addClass("inc__has-hover-ripple");
        $(e).wrapInner('<span class="inc__ripple-text"></span>');
        $(e).prepend("<span class='inc__ripple-hover'></span>");
      }
    });
  }

  // add ripple effect on hover

  const ELEMENTS = document.querySelectorAll(".inc__has-hover-ripple");
  const ELEMENTS_SPAN = [];

  ELEMENTS.forEach((element, index) => {
    // If The span element for this element does not exist in the array, add it.
    if (!ELEMENTS_SPAN[index]) ELEMENTS_SPAN[index] = element.querySelector(".inc__ripple-hover");

    element.addEventListener("mouseover", (e) => {
      let rect = element.getBoundingClientRect();

      ELEMENTS_SPAN[index].style.left = e.clientX - rect.left + "px";
      ELEMENTS_SPAN[index].style.top = e.clientY - rect.top + "px";
    });

    element.addEventListener("mouseout", (e) => {
      let rect = element.getBoundingClientRect();

      ELEMENTS_SPAN[index].style.left = e.clientX - rect.left + "px";
      ELEMENTS_SPAN[index].style.top = e.clientY - rect.top + "px";
    });
  });

  // add ripple effect on hover end
}
