const sectionLinks = [];
import { makeProgressBarAlive } from "./progress-bar";
export function appendFestivalDates() {
  if ($(".inc__ajax-fest-dates")[0] !== undefined) {
    $(".inc__ajax-fest-dates .items_also .press_item").each(function (index, item) {
      const url = $(this).find(".press_item_link").attr("href");

      $(item)
        .find(".press_item_link")
        .on("click", function (e) {
          e.preventDefault();
          var container = $("main");
          $.ajax({
            url: url, // Gets the URL from the link's href attribute
            type: "GET", // or 'POST', depending on your requirements
            success: function (response) {
              var htmlContent = $("<div/>").html(response);

              $(".inc__progress-container").children().unwrap();
              if (index === 0) {
                window.location.href = $(item).find(".press_item_link").attr("href");
              } else {
                // Select the elements to keep
                var mainSlideWrapper = $("#main_slide_wrapper");
                var firstWrapperBlock = $(".wrapper_block.content_wrapper").eq(0); // Select the second instance

                var secondWrapperBlock = $(".wrapper_block.content_wrapper").eq(1); // Select the second instance

                // Remove everything else except the selected elements
                $("main").children().not(mainSlideWrapper).not(firstWrapperBlock).not(secondWrapperBlock).remove();

                $("main").append(htmlContent.find(".wrapper_block.content_wrapper").eq(1));
              }

              if ($("h5").length > 0) {
                // add scrollbar
                if ($($("main").children()[0]).hasClass("main_slide_wrapper")) {
                  $($("main").children()[1]).nextUntil("#footer_wrapper").wrapAll(`<div class="inc__progress-container"></div>`);
                } else {
                  $($("main").children()[0]).nextUntil("#footer_wrapper").wrapAll(`<div class="inc__progress-container"></div>`);
                }
              }

              makeProgressBarAlive(sectionLinks);
              document.querySelector(".progress-bar").setAttribute("cx", "62");
              if ($(".inc__progress-bar--extra-button")[0] !== undefined) {
                const link = $(".inc__progress-bar--extra-button").find("a");
                link.addClass("btn btn--progress-bar");
                if ($(".inc__progress-circle-container")[0]) {
                  $(".inc__progress-circle-container").append(link);
                }
              }
              $(".inc__progress-circle-container").css("opacity", "1");
            },
            error: function (xhr, status, error) {
              console.error(error); // Log the error to the console for debugging
            },
          });
        });
    });
  }
}
