// let makeRipple = (index = undefined, numRings = 1, dotsPerRing = 64, ringEndAmt = 0.5, rippleMs = 1000) => {
//   // ringEndAmt: The portion of ripple filled by rings (0.5 = half the radius is empty of dots)
//   // rippleMs: The amount of time between the inner layer and outer layer pulsing

//   let ripple = document.createElement("div");
//   $(ripple).addClass(`ripple ripple${index}`);

//   for (let r = 0; r < numRings; r++) {
//     for (let d = 0; d < dotsPerRing; d++) {
//       // `r` indexes the layer we're in, from inner to outermost
//       // `d` indexes the specific dot within layer `r`

//       let radius = 1 - (r / numRings) * ringEndAmt;
//       let angAmt = (d / dotsPerRing) * Math.PI * 2;

//       let [x, y] = [Math.cos(angAmt), Math.sin(angAmt)].map((v) => v * radius);

//       let dot = document.createElement("div");
//       dot.classList.add("dot");
//       dot.style.left = `${(x + 1) * 50}%`;
//       dot.style.top = `${(y + 1) * 50}%`;
//       dot.style.animationDelay = `${Math.round((1 - (r + 1) / numRings) * rippleMs)}ms`;
//       ripple.appendChild(dot);
//     }
//   }

//   return ripple;
// };

// const ringsNum = 6;
import { teamDots } from "../animations/team-dots";
export function styleTeamPage() {
  if ($(".inc__team-om_page-view")[0] !== undefined) {
    $(".article_block_item").each(function (index) {
      if (index === 0) {
        $(this).addClass("inc__first-team-member");
      } else if (index % 2 === 1) {
        $(this).add($(this).next(".article_block_item")).wrapAll('<div class="inc__team-unit"></div>');
      }
    });
  }

  teamDots();
  // for (let i = 0; i < ringsNum; i++) {
  //   $(".inc__first-team-member .article_block_item_img").append(makeRipple(i));
  // }
}
