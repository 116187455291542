export function teamDots() {
  const $container = $(".inc__first-team-member .article_block_item_img");
  const numCircles = 80; // Number of circles per radial set
  const initialRadius = 140; // Starting radius
  const finalRadius = 1050; // Final radius
  const circleSize = 1.3; // Diameter of the circles
  const interval = 7000; // Interval in milliseconds
  const canvasMinSize = 500; // Minimum canvas size (500px)

  // Ensure the container exists
  if ($container.length) {
    // Center of the container
    const containerWidth = $container.width();
    const containerHeight = $container.height();
    const canvasSize = Math.max(containerWidth, containerHeight, canvasMinSize);
    const centerX = canvasSize / 2;
    const centerY = canvasSize / 2;

    // Ensure the container has relative positioning for proper placement
    $container.css("position", "relative");

    const createAndAnimateCircles = () => {
      // Create a new canvas for this animation cycle
      const canvas = $("<canvas></canvas>")
        .attr("width", canvasSize)
        .attr("height", canvasSize)
        .css({
          position: "absolute",
          top: "50%",
          left: "50%",
          pointerEvents: "none",
          transform: "translate(-50%, -50%)",
        })
        .appendTo($container)[0];

      const ctx = canvas.getContext("2d");
      const animationData = { radius: initialRadius }; // Track the current radius
      const angles = Array.from({ length: numCircles }, (_, i) => (i / numCircles) * Math.PI * 2);

      // Animate the radius
      gsap.to(animationData, {
        radius: finalRadius,
        duration: 200,
        ease: "power2.out",
        onUpdate: () => {
          // Clear the canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw the circles
          angles.forEach((angle) => {
            const x = centerX + animationData.radius * Math.cos(angle);
            const y = centerY + animationData.radius * Math.sin(angle);

            ctx.beginPath();
            ctx.arc(x, y, circleSize / 2, 0, Math.PI * 2);
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.fill();
          });
        },
        onComplete: () => {
          // Remove the canvas after animation completes
          gsap.to({}, { duration: 1, onComplete: () => $(canvas).remove() });
        },
      });
    };

    // Set an interval to create and animate circles every `interval` milliseconds
    setInterval(createAndAnimateCircles, interval);

    // Trigger the first set immediately
    createAndAnimateCircles();
  }
}
