let eventsLabelsId = 2; // this is a winter festival ID
const winterFestID = 2;
const summerFestID = 3;

import { slugify } from "./progress-bar";
import { createProgressBar } from "./progress-bar";
const winterFestivalPage = $("body").hasClass("simple_page_115");
const queenSilviaHallPage = $("body").hasClass("simple_page_113");
const summerFestivalPage = $("body").hasClass("simple_page_114");
if (winterFestivalPage) {
  eventsLabelsId = winterFestID;
} else if (summerFestivalPage) {
  eventsLabelsId = summerFestID;
}

function formatDateTime(input) {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (input.includes("-")) {
    const [dateStart, dateEnd] = input.split("-");

    const [dateStartDate, dateStartTime] = dateStart.split(" • ");
    const [dateEndDate, dateEndTime] = dateEnd.split(" • ");
    const [dateStartDay, dateStartMonth, dateStartYear] = dateStartDate.split("/").map(Number);
    const [dateEndDay, dateEndMonth, dateEndYear] = dateEndDate.split("/").map(Number);

    const dateStartProgramatic = new Date(dateStartYear, dateStartMonth - 1, dateStartDay);
    const dateEndProgramatic = new Date(dateEndYear, dateEndMonth - 1, dateEndDay);

    if (dateStartMonth == dateEndMonth) {
      // console.log(`${dateStartDay}-${dateEndDay} ${shortMonth}`);
      const shortMonth = shortMonths[dateStartProgramatic.getMonth()];
      return `${dateStartDay}-${dateEndDay} ${shortMonth}`;
    } else {
      const shortMonthStart = shortMonths[dateStartProgramatic.getMonth()];
      const shortMonthEnd = shortMonths[dateEndProgramatic.getMonth()];

      return `${dateStartDay} ${shortMonthStart}-${dateEndDay} ${shortMonthEnd}`;
    }
  } else {
    const [datePart, timePart] = input.split(" • ");

    if (!datePart || !timePart) {
      throw new Error("Invalid date-time format");
    }

    const [day, month, year] = datePart.split("/").map(Number);
    const date = new Date(year, month - 1, day);

    const dayName = days[date.getDay()];
    const shortMonth = shortMonths[date.getMonth()];
    const [hours, minutes] = timePart.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes === 0 ? "" : `.${minutes}`;
    // const formatedHoursMin = formattedHours + formattedMinutes + period;

    if (winterFestivalPage || summerFestivalPage) {
      if (hours === 0 && minutes === 1) {
        return `${dayName} ${day} ${shortMonth}`;
      } else {
        return `${dayName} ${day} ${shortMonth}, ${formattedHours}${formattedMinutes} ${period}`;
      }
    } else if (queenSilviaHallPage) {
      if (hours === 0 && minutes === 1) {
        return `${day} ${shortMonth} ${year}`;
      } else {
        return `${day} ${shortMonth} ${year}, ${formattedHours}${formattedMinutes} ${period}`;
      }
    }
  }
}

export function getEventsByLabel() {
  $.ajax({
    url: "/ajax/get_events_by_date_and_location_ajax",
    type: "POST",
    data: {
      start_date: "01 September 2024",
      end_date: "01 September 2025",
      location_id: 4, // Example location ID
    },
    dataType: "json",
    success: function (response) {
      if (response.error) {
        console.error(response.error);
      } else {
        console.log("Event URLs:", response);
        // Process the event URLs as needed
      }
    },
    error: function (xhr, status, error) {
      console.error("AJAX request failed:", error);
    },
  });

  console.log(summerFestivalPage);
  function fetchEventUrls(eventsLabelsId) {
    // winter festival
    if (winterFestivalPage || summerFestivalPage) {
      console.log("ajax");
      return $.ajax({
        url: "/ajax/get_events_ajax",
        type: "POST",
        data: { events_labels_id: eventsLabelsId },
        dataType: "json",
      });
      // queen silvia hall
    } else if (queenSilviaHallPage) {
      const currentYear = new Date().getFullYear();
      const startDate = `01 January ${currentYear}`;
      const endDate = `01 January ${currentYear + 1}`;
      return $.ajax({
        url: "/ajax/get_events_by_date_and_location_ajax",
        type: "POST",
        data: {
          start_date: startDate,
          end_date: endDate,
          //  choose location id
          location_id: 75, // Example location ID
        },
        dataType: "json",
      });
    }
  }

  function fetchEventContent(event) {
    return $.ajax({
      url: `concerts/${event.id}-${event.alias}`,
      type: "GET",
    }).then((response) => {
      const htmlContent = $("<div/>").html(response);
      const eventImg = $(htmlContent).find("#event_page_main_slide img");
      const eventDateTime = $(htmlContent).find(".event_info_item_title:not(.inc__event_info--name-city)");
      const ticketsLink = $(htmlContent).find(".event_page_left_inner .btn.btn-simple.btn-block");
      const eventProgram = $(htmlContent).find(".event_page_content_inner");
      const location1 = $(htmlContent).find(".inc__event_info--name-city__name");
      const eventTitle = $(htmlContent).find(".event_page_left_title");

      const timeFormatForLeftMenu = formatDateTime(eventDateTime.text().trim());

      return `
        <div class="article_content_block" data-incblock="text-block">
          <div class="article_content_text">
            <div class="content_text">
            ${winterFestivalPage ? "<h5>" + timeFormatForLeftMenu + "</h5>" : ""}
            </div>
          </div>
        </div>
        <div class="inc__event-card" data-inctimecompare="">
          <div class="inc__event-card__img">
            <img src="${$(eventImg).attr("src")}" alt="" />
          </div>
          <div class="concert-card__info">

                   <div class="inc__event-card__title">
  ${location1.html() ? `${location1.html()} | ` : ""}${eventTitle.html()}
</div>

            ${queenSilviaHallPage ? '<p class="inc__event-card__date">' + timeFormatForLeftMenu + "</p>" : ""}
            ${summerFestivalPage ? '<p class="inc__event-card__date">' + timeFormatForLeftMenu + "</p>" : ""}




            <div class="inc__event-card__program">
              ${eventProgram.html()}
            </div>
            <div class="concert-card__action">
              ${ticketsLink.length !== 0 ? ticketsLink.prop("outerHTML") : ""}
            </div>
          </div>
        </div>
      `;
    });
  }

  fetchEventUrls(eventsLabelsId)
    .then((response) => {
      if (response.error) {
        console.error(response.error);
        return;
      }

      const eventPromises = response.map(fetchEventContent);

      Promise.all(eventPromises).then((contents) => {
        contents.forEach((content) => {
          $(".inc__festival-concerts-js").append(content);
        });

        // Create the progress bar after all content is appended
        createProgressBar();
      });
    })
    .catch((error) => {
      console.error("Failed to fetch event URLs:", error);
    });
}
