export function smallPageUpdates() {
  if ($("#footer_narrow")[0] !== undefined) {
    const logo = $(".logo_main_menu").clone();
    const html = `<div class="inc__footer-logo">` + logo + `</div>`;
    $("#footer_narrow").before(`<div class="inc__footer-logo">${logo.html()}</div>`);
  }

  $(".content_text h3").each(function () {
    if ($(this).next("table").length > 0) {
      $(this).addClass("inc__heading-table");
    }
  });

  if ($(".footer_button a")[0] !== undefined) {
    $(".footer_button a").attr("href", "https://mailchi.mp/omodernt/keepupdated");
  }

  if ($(".inc__member-lvl")[0] !== undefined) {
    $(".inc__member-lvl").closest("p").addClass("inc__member-lvl-wrapper");
  }
}
