// HAS OBSERVER

// Utility function to get the YouTube thumbnail
function getYouTubeThumbnail(url) {
  const videoId = url.match(/(?:youtube\.com.*(?:\?v=|\/embed\/)|youtu\.be\/)(.{11})/)?.[1];
  return videoId ? `http://img.youtube.com/vi/${videoId}/0.jpg` : false;
}

// Function to wrap iframes with necessary markup
function wrapIframe(video, videoSRC) {
  const thumbnail = getYouTubeThumbnail(videoSRC);
  $(video).wrap(`<div class='inc__youtube-container'></div>`);
  $(video).after(`<div class="inc__youtube-overlay" data-modal-video-src="${videoSRC}?autoplay=1"></div>`);

  if (!video.classList.contains("inc__iframe-loaded")) {
    video.setAttribute("data-src", videoSRC);
    video.setAttribute("loading", "lazy");
    video.removeAttribute("src");
  }
}

// Function to setup the modal functionality
function setupModal() {
  $("body").append(`
    <div id="youtube-modal" class="inc__youtube-modal">
      <div class="inc__youtube-modal-content">
        <iframe class="inc__youtube-video-in-modal" id="youtube-video" width="560" height="315" src="" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
        <div class="inc__youtube-modal-close">
        <span class="material-icons">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </span>
        </div>
      </div>
    </div>
  `);

  $("body").on("click", ".inc__youtube-overlay", function () {
    const videoSRC = $(this).data("modal-video-src");
    $("#youtube-video").attr("src", videoSRC);
    $("#youtube-modal").css("display", "block");
  });

  $("body").on("click", ".inc__youtube-modal-close", function () {
    $("#youtube-video").attr("src", "");
    $("#youtube-modal").css("display", "none");
  });
}

// Intersection Observer for lazy loading
function lazyLoadIframe(iframe, observer) {
  const dataSrc = iframe.getAttribute("data-src");
  if (dataSrc) {
    iframe.setAttribute("src", dataSrc);
    iframe.removeAttribute("data-src");
    iframe.classList.add("inc__iframe-loaded");
  }
  observer.unobserve(iframe);
}

// Function to observe iframe loading
function setupLazyLoading(iframes) {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          lazyLoadIframe(entry.target, observer);
        }
      });
    },
    {
      rootMargin: "50px 0px",
      threshold: 0.01,
    }
  );

  iframes.forEach((iframe) => {
    if (!iframe.classList.contains("inc__iframe-loaded")) {
      observer.observe(iframe);
    }
  });
}

// Main function to initialize the iframe handling
function initializeIframes() {
  const iframes = [...document.getElementsByTagName("iframe")];

  iframes.forEach((iframe) => {
    const videoSRC = $(iframe).attr("src");
    wrapIframe(iframe, videoSRC);
  });

  setupModal();
  setupLazyLoading(iframes);
}

// Export the function to be used in script.js
export { initializeIframes };
