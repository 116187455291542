// const popupContent = `
//     <div id="inc__pop-newsletter-popup" class="inc__pop-popup">
//         <div class="inc__pop-popup-content">

//         <div class="inc__pop-popup-content--wrapper">
//             <span class="inc__pop-close-btn">&times;</span>
//             <h4 class="inc__pop--heading">Join our mailing list</h4>

//             <p class="inc__pop--description" style="font-weight: bold">Free track download of 'Across The Universe' by O/Modernt Chamber Orchestra on signing up!</p>

//             <a class="btn btn-simple inc__has-hover-ripple" href="https://mailchi.mp/omodernt/keepupdated">
//             Join
//             </a>

//             </div>
//         </div>
//     </div>

// `;

const popupContent = `
<div id="inc__pop-newsletter-popup" class="inc__pop-popup inc__pop-popup--2">

  <div class="inc__pop-popup-content">
      <span class="inc__pop-close-btn">&times;</span>

    <div class="inc__pop-popup-content--wrapper">
      <div class="inc__popup-logo-img">
        <img src="/uploads/images/logo/omodernt-logo-popup-form.png" alt="logo" />
      </div>
      <h4 class="inc__pop--heading">Sign-up to mailing list</h4>

      <form action="#" method="POST" class="show_block_js ajax_auth_form_js" id="subscribe_email_form">
        <div class="input-field">
          <input type="text" id="popup-form-name" class="inc_form-input" name="firstName" placeholder="First name" required />
          <label class="inc_form-label" for="popup-form-name">First name</label>
          <p class="inc_form--error-msg">First name cannot be blank</p>
        </div>
        <div class="input-field">
          <input type="text" id="popup-form-lastname" class="inc_form-input" name="lasttName" placeholder="Last name" required />
          <label class="inc_form-label" for="popup-form-lastname">First name</label>
          <p class="inc_form--error-msg">Last name cannot be blank</p>

        </div>

        <div class="input-field">
          <input type="text" name="email" class="inc_form-input" id="subscribe_email_field" required="" placeholder="Email" />
          <label for="subscribe_email_field" class="inc_form-label">E-mail<span class="required">*</span></label>
          <p class="inc_form--error-msg">Email name cannot be blank</p>

        </div>
        <div class="input-field input-field--btn text-center">
          <button class="btn btn-simple btn-block waves-effect waves-light" type="submit">Subscribe</button>
        </div>
        <div class="text-center form_result ajax_form_result_js"></div>
      </form>
    </div>
  </div>
</div>
`;

// id="subscribe_email_form"
const popupContentMailchimp = `
<div class="inc__pop-popup inc__pop-popup--2" id="inc__pop-newsletter-popup">
  <div class="inc__pop-popup-content" id="mc_embed_shell">
    <span class="inc__pop-close-btn">×</span>
    <div class="inc__pop-popup-content--wrapper" id="mc_embed_signup">
      <div class="inc__pop-popup-content--width">
        <div class="inc__popup-logo-img"><img alt="logo" src="/uploads/images/logo/omodernt-logo-popup-form.png" /></div>

        <h4 class="inc__pop--heading">Sign-up to mailing list</h4>
      </div>

      <form
        action="https://omodernt.us3.list-manage.com/subscribe/post?u=6260daa911fea39a3bbbf649e&amp;id=bf432a325f&amp;f_id=00102ee2f0"
        class="validate"
        id="mc-embedded-subscribe-form"
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <div class="inc__pop-popup-content--width">
            <div class="mc-field-group input-field">
              <label class="inc_form-label" for="mce-EMAIL">Email Address <span class="asterisk">*</span></label>
              <input class="required email inc_form-input" id="mce-EMAIL" name="EMAIL" placeholder="Email" required="" type="email" value="" />
              <p class="inc_form--error-msg">Email cannot be blank</p>
            </div>

            <div class="mc-field-group input-field">
              <label class="inc_form-label" for="mce-FNAME">First Name <span class="asterisk">*</span></label
              ><input class="required text inc_form-input" id="mce-FNAME" name="FNAME" placeholder="First name" required="" type="text" value="" />
              <p class="inc_form--error-msg">First name cannot be blank</p>
            </div>

            <div class="mc-field-group input-field">
              <label class="inc_form-label" for="mce-LNAME">Last Name <span class="asterisk">*</span></label
              ><input class="required text inc_form-input" id="mce-LNAME" name="LNAME" placeholder="Last name" required="" type="text" value="" />
              <p class="inc_form--error-msg">Last name cannot be blank</p>
            </div>
          </div>

          <div class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group" id="mergeRow-gdpr">
            <div class="content__gdpr">
              <label>Please, confirm that you&#39;re happy for us to contact you with updates</label>

              <p>
                We manage personal and sensitive data for the only purpose of sending our newsletters and in accordance with the requirements of the General
                Data Protection Regulation (GDPR).
              </p>

              <fieldset class="mc_fieldset gdprRequired mc-field-group" id="gdpr-check" name="interestgroup_field">
                <label class="checkbox subfield" for="gdpr40941"
                  ><input class="gdpr" id="gdpr_40941" name="gdpr[40941]" type="checkbox" value="Y" /><span
                    >I&#39;m happy to receive newsletter and direct email from O/Modernt</span
                  ></label
                >
              </fieldset>
            </div>

            <div class="content__gdprLegal">
              <p>You can unsubscribe at any time by clicking the link in the footer of our emails.</p>

              <p>
                We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to
                Mailchimp for processing. <a href="https://mailchimp.com/legal/terms">Learn more about Mailchimp&#39;s privacy practices</a>.
              </p>
            </div>
          </div>

          <div class="clear" id="mce-responses">
            <div class="response" id="mce-error-response" style="display: none"> </div>

            <div class="response" id="mce-success-response" style="display: none"> </div>
          </div>

          <div aria-hidden="true" style="position: absolute; left: -5000px">
            <input name="b_6260daa911fea39a3bbbf649e_bf432a325f" tabindex="-1" type="text" value="" />
          </div>

          <div class="clear"><input class="btn btn-simple" id="mc-embedded-subscribe" name="subscribe" type="submit" value="Subscribe" /></div>
        </div>
      </form>
    </div>
  </div>
</div>

`;

function validateForm() {
  $("body").on("click", "#gdpr-check", function () {
    let checkbox = $(this).find("input");
    checkbox.prop("checked", !checkbox.prop("checked"));
  });
  // Attach focus and blur event listeners
  $("body").on("focus", ".inc_form-input", function () {
    $(this).removeClass("show-err");
    // When the input is clicked or focused, hide any existing error messages
    $(this).siblings(".inc_form--error-msg").hide();
  });

  $("body").on("blur", ".inc_form-input", function () {
    // When the input loses focus, check if it's empty
    if ($(this).val().trim() === "") {
      // Show the error message if the input is empty
      $(this).siblings(".inc_form--error-msg").show();
      $(this).addClass("show-err");
    }
  });
}
export function addPopup() {
  $("body").append(popupContentMailchimp);

  $(".footer_button a").click(function (e) {
    e.preventDefault();
    $("#inc__pop-newsletter-popup").fadeIn();

    $("body").addClass("inc__pop-no-scroll"); // Disable scrolling
    validateForm();
  });

  if (!document.cookie.includes("inc__pop-newsletter_popup_shown=true")) {
    function setNewsletterShownCookie() {
      var date = new Date();
      // date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 hours
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds
      document.cookie = "inc__pop-newsletter_popup_shown=true; expires=" + date.toUTCString() + "; path=/";
    }
    // // Check if the cookie exists
    setTimeout(function () {
      $("#inc__pop-newsletter-popup").fadeIn();
      setNewsletterShownCookie();
      $("body").addClass("inc__pop-no-scroll"); // Disable scrolling
      validateForm();
    }, 5000); // Show after 5 seconds

    function setNewsletterSubscribedCookie() {
      var date = new Date();
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 year
      document.cookie = "inc__pop-newsletter_subscribed=true; expires=" + date.toUTCString() + "; path=/";
    }

    $("#mc-embedded-subscribe-form").on("submit", function (e) {
      // e.preventDefault();
      // Simulate a successful form submission (Replace with actual AJAX request if needed)
      setNewsletterSubscribedCookie();

      // Hide popup and remove scroll lock
      $("#inc__pop-newsletter-popup").fadeOut();
      $("body").removeClass("inc__pop-no-scroll");
    });
  }

  // Close the popup
  $(".inc__pop-close-btn").click(function () {
    $("#inc__pop-newsletter-popup").fadeOut();
    $("body").removeClass("inc__pop-no-scroll"); // Enable scrolling
  });
}
