function wrapTabContent(tabsBorderClassesArr, tabClassPrefix, contentClass) {
  tabsBorderClassesArr.forEach((selectorPair, i) => {
    $(selectorPair[0])
      .nextUntil(selectorPair[1])
      .wrapAll(`<div id="${tabClassPrefix}${i + 1}" class="${contentClass}"></div>`);
  });
}

function handleTabClicks(tabsClass, contentClass) {
  $(`${tabsClass} a`).click(function (e) {
    e.preventDefault();

    // Hide all tab content
    $(`.${contentClass}`).hide();

    // Remove active class from all tabs links
    $(`${tabsClass} li`).removeClass("inc__is-open");

    // Add active class to clicked tab link
    $(this).parent().addClass("inc__is-open");

    // Show corresponding tab content
    const tab = $(this).data("tab");
    $(`#${tab}`).show();
  });
}

function addTabs(tabsBorderClassesArr, isSecond = false) {
  const tabClassPrefix = isSecond ? "tab2-" : "tab";
  const contentClass = isSecond ? "inc__tabContent--2" : "inc__tabContent";
  const tabsClass = isSecond ? ".inc__tabs--2" : ".inc__tabs";

  // Wrap content in tabs
  wrapTabContent(tabsBorderClassesArr, tabClassPrefix, contentClass);

  // Hide all tab content except the first one
  $(`.${contentClass}`).not(":first").hide();

  // Handle click events for the tabs
  handleTabClicks(tabsClass, contentClass);
}

export function appendTabs() {
  if ($(".inc__collab-start")[0] !== undefined && $(".inc__orch-start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__collab-start", ".inc__collab-end"],
      [".inc__orch-start", ".inc__orch-end"],
    ];

    addTabs(tabsBorders);
  }

  //winter festival tabs
  if ($(".inc__magical-world--start")[0] !== undefined && $(".inc__qsch--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__magical-world--start", ".inc__magical-world--end"],
      [".inc__qsch--start", ".inc__qsch--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__what-we-do--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__what-we-do--start", ".inc__what-we-do--end"],
      [".inc__whats-on--start", ".inc__whats-on--end"],
      [".inc__our-family--start", ".inc__our-family--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__current-activities--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__current-activities--start", ".inc__current-activities--end"],
      [".inc__past-activities--start", ".inc__past-activities--end"],
      [".inc__new-generation--start", ".inc__new-generation--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__winter-2021_first-prize--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__winter-2021_first-prize--start", ".inc__winter-2021_first-prize--end"],
      [".inc__winter-2021_special-distinction--start", ".inc__winter-2021_special-distinction--end"],
    ];
    addTabs(tabsBorders);
  }
  if ($(".inc__concertos--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__concertos--start", ".inc__concertos--end"],
      [".inc__playdirect--start", ".inc__playdirect--end"],
      [".inc__chambermusic--start", ".inc__chambermusic--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__future-commissions--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__future-commissions--start", ".inc__future-commissions--end"],
      [".inc__past-commissions--start", ".inc__past-commissions--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__philosophy--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__philosophy--start", ".inc__philosophy--end"],
      [".inc__hall--start", ".inc__hall--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__concertsandevents--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__concertsandevents--start", ".inc__concertsandevents--end"],
      [".inc__artandeducation--start", ".inc__artandeducation--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__alleinseinclosingconcerts--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__alleinseinclosingconcerts--start", ".inc__alleinseinclosingconcerts--end"],
      [".inc__alleinseinoneononeperformance--start", ".inc__alleinseinoneononeperformance--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__nabs-commissions--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__nabs-commissions--start", ".inc__nabs-commissions--end"],
      [".inc__nabs-partners--start", ".inc__nabs-partners--end"],
      [".inc__nabs-concerts--start", ".inc__nabs-concerts--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__our-milestone21-programme--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__our-milestone21-programme--start", ".inc__our-milestone21-programme--end"],
      [".inc__our-milestone21-dates--start", ".inc__our-milestone21-dates--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__unsung-programme--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__unsung-programme--start", ".inc__unsung-programme--end"],
      [".inc__unsung-dates--start", ".inc__unsung-dates--end"],
    ];
    addTabs(tabsBorders);
  }
  if ($(".inc__reconceptions-programme--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__reconceptions-programme--start", ".inc__reconceptions-programme--end"],
      [".inc__reconceptions-dates--start", ".inc__reconceptions-dates--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__concertseries-202324--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__concertseries-202324--start", ".inc__concertseries-202324--end"],
      [".inc__tab-divider inc__concertseries-202223--start", ".inc__tab-divider inc__concertseries-202223--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__video-playdirect--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__video-playdirect--start", ".inc__video-playdirect--end"],
      [".inc__video-conduct--start", ".inc__video-conduct--end"],
    ];
    addTabs(tabsBorders, true);
  }

  if ($(".inc__from-the-ground-up--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__from-the-ground-up--start", ".inc__from-the-ground-up--end"],
      [".inc__white-light--start", ".inc__white-light--end"],
      [".inc__sonic-philosophy--start", ".inc__sonic-philosophy--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__jacob--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__jacob--start", ".inc__jacob--end"],
      [".inc__qsch14--start", ".inc__qsch14--end"],
    ];
    addTabs(tabsBorders);
  }

  if ($(".inc__official-orch--start")[0] !== undefined) {
    const tabsBorders = [
      [".inc__official-orch--start", ".inc__official-orch--end"],
      [".inc__official-collab--start", ".inc__official-collab--end"],
      [".inc__official-chamb--start", ".inc__official-chamb--end"],
      [".inc__official-hugo-concert--start", ".inc__official-hugo-concert--end"],
      [".inc__official-hugo-play-direct--start", ".inc__official-hugo-play-direct--end"],
    ];
    addTabs(tabsBorders);
  }
}
