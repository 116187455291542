export function printPrograms() {
  if ($(".simple_page_121").length && window.location.href.includes("programmes?official")) {
    $(document).ready(function () {
      // Append "Print Programs" button and container for selected titles
      $("body").prepend(`
        <button id="showCheckboxes" class="btn btn-simple btn--programs-print" style="margin: 10px;">Print Programs</button>
        <div id="selectedProgramsContainer" class="selected-for-print--container" style="margin: 20px; display: none;">
        <a href="#" id="selectedProgramsContainer--hide">x</a>
          <h4>Select Programs for Printing:</h4>
          <ul id="selectedProgramsList" style="padding: 10px; border: 1px solid #ccc; border-radius: 5px; background-color: #f9f9f9; list-style-type: none;"></ul>
          <button id="printSelected" class="btn btn-simple-filled" style="margin-top: 10px; display: none;">Print Selected</button>
        </div>
      `);

      // Show program titles when "Print Programs" is clicked
      $("#showCheckboxes").on("click", function () {
        $(this).hide();
        const selectedList = $("#selectedProgramsList");
        selectedList.empty(); // Clear the list

        // Populate the #selectedProgramsContainer with all program titles
        $("[data-incblock='chess-block']").each(function () {
          const title = $(this).find("h2").text().trim();
          if (title) {
            selectedList.append(`
              <li class="selectable-program" style="cursor: pointer; padding: 5px; margin-bottom: 5px; border: 1px solid #ccc; border-radius: 5px;">
                ${title}
              </li>
            `);
          }
        });

        // Show the container
        $("#selectedProgramsContainer").show();
      });

      $("#selectedProgramsContainer--hide").on("click", function (e) {
        e.preventDefault();
        $("#selectedProgramsContainer").hide();
        $("#showCheckboxes").show();
      });

      // Handle selection of programs
      $(document).on("click", ".selectable-program", function () {
        $(this).toggleClass("program-selected"); // Toggle selection
        $(this).css("background-color", $(this).hasClass("program-selected") ? "#d4edda" : "#f9f9f9"); // Green for selected, reset for unselected

        // Show or hide the "Print Selected" button based on selection
        if ($(".program-selected").length > 0) {
          $("#printSelected").show();
        } else {
          $("#printSelected").hide();
        }
      });

      // Handle the print functionality
      $("#printSelected").on("click", function () {
        const selectedTitles = [];
        $(".program-selected").each(function () {
          const title = $(this).text().trim();
          selectedTitles.push(title);
        });

        if (selectedTitles.length === 0) {
          alert("Please select at least one program to print.");
          return;
        }

        const selectedBlocks = [];
        $("[data-incblock='chess-block']").each(function () {
          const title = $(this).find("h2").text().trim();
          if (selectedTitles.includes(title)) {
            const block = $(this).clone();
            selectedBlocks.push(block.html());
          }
        });

        // Create a temporary printable document
        const printWindow = window.open("", "_blank");
        const printStyles = `
        <link rel="stylesheet" href="/custom/css/style.css" />
        <style>
          @media print {
            .page-break { page-break-before: always; }
            .article_block_item_inner { display: flex; }
         img {object-fit: cover;}
          }
        </style>
      `;
        printWindow.document.write("<html><head>" + printStyles + "</head><body>");

        // Add each block as a separate page
        selectedBlocks.forEach((block) => {
          printWindow.document.write(`<div class="page-break">${block}</div>`);
        });

        printWindow.document.write("</body></html>");
        printWindow.document.close();

        // Trigger the print dialog
        printWindow.print();
      });
    });
  }
}

// export function printPrograms() {
//   if ($(".simple_page_121 ").length) {
//     $(document).ready(function () {
//       // Append "Print Programs" button and container for selected titles
//       $("body").prepend(`
//     <button id="showCheckboxes" class="btn btn-simple btn--programs-print " style="margin: 10px;">Print Programs</button>
//     <div id="selectedProgramsContainer" class="selected-for-print--container" style="margin: 20px; display: none;">
//       <h4>Selected Programs:</h4>
//       <ul id="selectedProgramsList" style="padding: 10px; border: 1px solid #ccc; border-radius: 5px; background-color: #f9f9f9; list-style-type: none;"></ul>
//       <button id="printSelected" class="btn btn-simple-filled" style="margin-top: 10px;">Print Selected</button>
//     </div>
//   `);

//       // Show checkboxes when "Print Programs" is clicked
//       $("#showCheckboxes").on("click", function () {
//         $(this).hide();
//         // Append checkboxes to each chess-block
//         $("#printSelected").hide();

//         $("[data-incblock='chess-block'] .article_block_text_inner").each(function () {
//           if (!$(this).find(".program-checkbox").length) {
//             $(this).prepend(`
//           <label class="btn btn-simple" style="display: block; margin-bottom: 5px;">
//             <input type="checkbox" class="program-checkbox" />
//             Select for Printing
//           </label>
//         `);
//           }
//         });

//         // Show the selected programs container
//         $("#selectedProgramsContainer").show();
//       });

//       $(document).on("change", ".program-checkbox", function () {
//         const selectedList = $("#selectedProgramsList");
//         selectedList.empty(); // Clear the list

//         // Update the label for each checkbox based on its state
//         $(".program-checkbox").each(function () {
//           const label = $(this).closest("label");
//           if ($(this).is(":checked")) {
//             label.text("Remove from printing");
//             label.addClass("program-selected");
//           } else {
//             label.text("Select for printing");
//             label.removeClass("program-selected");
//           }
//           // Re-add the checkbox after changing the label
//           label.prepend(this);
//         });

//         // Populate the list with selected program titles
//         $(".program-checkbox:checked").each(function () {
//           const title = $(this).closest("[data-incblock='chess-block']").find("h2").text().trim();
//           selectedList.append(`<li>${title}</li>`);
//         });

//         // Show or hide the print button based on selection
//         if ($(".program-checkbox:checked").length > 0) {
//           $("#printSelected").show();
//         } else {
//           $("#printSelected").hide();
//         }
//       });
//       // Handle the print functionality
//       $("#printSelected").on("click", function () {
//         // Collect selected blocks
//         $(".program-selected").css("display", "none");
//         const selectedBlocks = [];
//         $(".program-checkbox:checked").each(function () {
//           const block = $(this).closest("[data-incblock='chess-block']").clone();
//           block.find(".program-checkbox").remove(); // Remove checkboxes for print
//           selectedBlocks.push(block.html());
//         });

//         if (selectedBlocks.length === 0) {
//           alert("Please select at least one program to print.");
//           return;
//         }

//         // Create a temporary printable document
//         const printWindow = window.open("", "_blank");
//         const printStyles = `
//         <link rel="stylesheet" href="/custom/css/style.css" />
//       <style>
//         @media print {
//           .page-break { page-break-before: always; }
//           .article_block_item_inner {display: flex;}

//         }

//       </style>
//     `;
//         printWindow.document.write("<html><head>" + printStyles + "</head><body>");

//         // Add each block as a separate page
//         selectedBlocks.forEach((block, index) => {
//           printWindow.document.write(`<div class="page-break">${block}</div>`);
//         });

//         printWindow.document.write("</body></html>");
//         printWindow.document.close();

//         // Trigger the print dialog
//         printWindow.print();
//       });
//     });
//   }
// }
