// Function to initialize the slider with event listeners
function initializeSlider() {
  const listImages = document.querySelector(".inc__slider--img-wrapper .inc__slider--img-content");
  const singleImageArr = document.querySelectorAll(".inc__slider--img-wrapper .inc__slider--img-content .inc__slider--img-img-and-btn");
  const listText = document.querySelector(".inc__slider--title--wrapper .inc__slider--title--content");
  const itemsText = document.querySelectorAll(".inc__slider--title--wrapper .inc__slider--title--content h2");
  const yearsDisplay = document.querySelectorAll(".inc__slider--year span");
  const prev = document.getElementById("inc-slider-prev");
  const next = document.getElementById("inc-slider-next");

  let active = 0;
  const totalItems = singleImageArr.length - 1;

  // Adjust the title wrapper height to fit the tallest slide
  adjustSliderHeight();

  // Event listeners for navigation buttons
  next.onclick = () => navigateSlider(1);
  prev.onclick = () => navigateSlider(-1);

  let autoSlide;
  let isPaused = false;

  // Start autoSlide
  startAutoSlide();

  $(".inc__slider-wrapper").hover(stopAutoSlide, startAutoSlide);
  $(".inc__slider-wrapper").on("touchstart", stopAutoSlide);
  $(".inc__slider-wrapper").on("touchend", startAutoSlide);

  yearsDisplay.forEach((year, key) => {
    year.addEventListener("click", () => {
      active = key;
      updateSlider();
    });
  });

  // Helper function to adjust the slider's height based on the tallest slide
  function adjustSliderHeight() {
    const wrapper = document.querySelector(".inc__slider--title--wrapper");
    const content = document.querySelector(".inc__slider--title--content");
    const allSlides = document.querySelectorAll(".inc__slider--title--content h2");

    content.style.position = "static";
    const maxHeight = Math.max(...Array.from(allSlides).map((slide) => slide.getBoundingClientRect().height));
    wrapper.style.height = `${maxHeight}px`;
    content.style.position = "absolute";
  }

  // Navigate slider: +1 (next) or -1 (previous)
  function navigateSlider(direction) {
    active = (active + direction + totalItems + 1) % (totalItems + 1);
    updateSlider();
  }

  // Update slider visuals based on the active slide
  function updateSlider() {
    const checkLeftImg = singleImageArr[active].offsetLeft;
    const checkLeftText = itemsText[active].offsetLeft;

    listImages.style.left = `-${checkLeftImg}px`;
    listText.style.left = `-${checkLeftText}px`;

    document.querySelector(".inc__year-active")?.classList.remove("inc__year-active");
    yearsDisplay[active].classList.add("inc__year-active");
  }

  // // Start automatic sliding
  function startAutoSlide() {
    if (!autoSlide) {
      autoSlide = setInterval(() => next.click(), 4000);
      isPaused = false;
    }
  }

  // Stop automatic sliding
  function stopAutoSlide() {
    clearInterval(autoSlide);
    autoSlide = null;
    isPaused = true;
  }
}

export function loadPastFestSlider() {
  if (!$(".inc__ajax-page").length) return;

  const url = $(".inc__ajax-page").find("a").attr("href");

  $.ajax({
    url: url,
    type: "GET",
    success: function (response) {
      const htmlContent = $("<div/>").html(response);
      const pastEditionItems = htmlContent.find(".items_also.press_items");

      const sliderContent = createSliderHTML();

      pastEditionItems.find(".press_item").each(function (index) {
        const year = $(this).find(".press_item_title").text();
        const festName = $(this).find(".press_item_info").text();
        const imgSrc = $(this).find(".press_item_image_narrow img").attr("src");
        const btnLink = $(this).find(".press_item_link").attr("href");

        appendSlideContent(sliderContent, festName, year, imgSrc, btnLink, index);
      });

      $(".inc__ajax-page").find("a").closest(".content_text").append(sliderContent);
      // $(".inc__ajax-page").find("a").remove();
      $(`.inc__ajax-page a[href="${url}"]`).remove();

      initializeSlider();
    },
    error: function (xhr, status, error) {
      console.error(`Error: ${status} - ${error}`);
    },
  });

  // Helper function to create slider structure
  function createSliderHTML() {
    return $(`
      <div class="inc__slider-wrapper">
        <div class="inc__slider--title--wrapper">
          <div class="inc__slider--title--content"></div>
        </div>
        <div class="inc__slider--year"></div>
        <div class="inc__slider--img-wrapper">
          <div class="inc__slider--img-content"></div>
          <div class="inc__slider-button--container">
            <div id="inc-slider-prev" class="inc__slider--btn inc__slider--btn-prev">
              <img src="/custom/images/btn-prev.svg" alt="Previous button" />
            </div>
            <div id="inc-slider-next" class="inc__slider--btn inc__slider--btn-next">
              <img src="/custom/images/btn-next.svg" alt="Next button" />
            </div>
          </div>
        </div>
      </div>
    `);
  }

  // Helper function to append slide content
  function appendSlideContent(sliderContent, festName, year, imgSrc, btnLink, index) {
    sliderContent.find(".inc__slider--title--content").append(`<h2 data-incslide="${index}">${festName}</h2>`);
    sliderContent.find(".inc__slider--year").append(`<span data-incslide="${index}" ${index === 0 ? 'class="inc__year-active"' : ""}>${year}</span>`);
    sliderContent.find(".inc__slider--img-content").append(`
      <div data-incslide="${index}" class="inc__slider--img-img-and-btn">
        <img src="${imgSrc}" alt="${festName}" />
        <a class="btn btn-simple btn--past-editions" href="${btnLink || "#"}">Download</a>
      </div>
    `);
  }
}
