import { addHeroText } from "./animateText";
export function updateNewspage() {
  // hide also block in the blog article

  if ($("body[class^='news_item']")[0] !== undefined) {
    if ($(".articles_item_label")[0].innerText === "Blog") {
      $(".title_semibig").css("display", "none");
      $(".news_items").css("display", "none");
      $(".btn-container.text-center").css("display", "none");
    }
  }

  // change news text to blog

  if (window.location.href.indexOf("news?labels_id=4") != -1) {
    $(".title_big")[0].innerHTML = "Blog";
    $("title")[0].innerHTML = "Blog";
    $("body").addClass("inc__blog-page");

    $(".calendar_filter_wrapper").css("display", "none");
  }

  if ($(".news_48")[0] !== undefined) {
    if ($(".inc__blog-page")[0] !== undefined) {
      const heroContent = `
    <div id="main_slide_wrapper" class="main_slide_wrapper">
  	<div id="event_page_main_slide">
  					<img width="1687" height="1080" src="/uploads/images/design/blog.png" alt="Blog page cover">
  	</div>
  	<div class="clear"></div>
  </div>
    `;
      $(".news_48").find("main").prepend(heroContent);
      // addHeroText($("h1").text());
    } else {
      const heroContent = `
    <div id="main_slide_wrapper" class="main_slide_wrapper">
  	<div id="event_page_main_slide">
  					<img width="1687" height="1080" src="/uploads/images/design/news.jpeg" alt="News page cover">
  	</div>
  	<div class="clear"></div>
  </div>
    `;
      $(".news_48").find("main").prepend(heroContent);
      addHeroText("News", "News is to the mind what sugar is to the body", "Rolf Dobelli", true);
    }
  }
}
